exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-socalyrg-com-afterrace-js": () => import("./../../../src/pages/socalyrg.com/afterrace.js" /* webpackChunkName: "component---src-pages-socalyrg-com-afterrace-js" */),
  "component---src-pages-socalyrg-com-calendar-js": () => import("./../../../src/pages/socalyrg.com/calendar.js" /* webpackChunkName: "component---src-pages-socalyrg-com-calendar-js" */),
  "component---src-pages-socalyrg-com-charities-js": () => import("./../../../src/pages/socalyrg.com/charities.js" /* webpackChunkName: "component---src-pages-socalyrg-com-charities-js" */),
  "component---src-pages-socalyrg-com-components-header-js": () => import("./../../../src/pages/socalyrg.com/components/header.js" /* webpackChunkName: "component---src-pages-socalyrg-com-components-header-js" */),
  "component---src-pages-socalyrg-com-components-layout-js": () => import("./../../../src/pages/socalyrg.com/components/layout.js" /* webpackChunkName: "component---src-pages-socalyrg-com-components-layout-js" */),
  "component---src-pages-socalyrg-com-components-regatta-js": () => import("./../../../src/pages/socalyrg.com/components/regatta.js" /* webpackChunkName: "component---src-pages-socalyrg-com-components-regatta-js" */),
  "component---src-pages-socalyrg-com-contact-js": () => import("./../../../src/pages/socalyrg.com/contact.js" /* webpackChunkName: "component---src-pages-socalyrg-com-contact-js" */),
  "component---src-pages-socalyrg-com-index-js": () => import("./../../../src/pages/socalyrg.com/index.js" /* webpackChunkName: "component---src-pages-socalyrg-com-index-js" */),
  "component---src-pages-socalyrg-com-orrora-js": () => import("./../../../src/pages/socalyrg.com/orrora.js" /* webpackChunkName: "component---src-pages-socalyrg-com-orrora-js" */),
  "component---src-pages-socalyrg-com-photo-group-js": () => import("./../../../src/pages/socalyrg.com/photo_group.js" /* webpackChunkName: "component---src-pages-socalyrg-com-photo-group-js" */),
  "component---src-pages-socalyrg-com-photos-js": () => import("./../../../src/pages/socalyrg.com/photos.js" /* webpackChunkName: "component---src-pages-socalyrg-com-photos-js" */),
  "component---src-pages-socalyrg-com-photos-template-js": () => import("./../../../src/pages/socalyrg.com/photos_template.js" /* webpackChunkName: "component---src-pages-socalyrg-com-photos-template-js" */),
  "component---src-pages-socalyrg-com-results-js": () => import("./../../../src/pages/socalyrg.com/results.js" /* webpackChunkName: "component---src-pages-socalyrg-com-results-js" */),
  "component---src-pages-socalyrg-com-sponsors-js": () => import("./../../../src/pages/socalyrg.com/sponsors.js" /* webpackChunkName: "component---src-pages-socalyrg-com-sponsors-js" */),
  "component---src-pages-socalyrg-com-thankyou-js": () => import("./../../../src/pages/socalyrg.com/thankyou.js" /* webpackChunkName: "component---src-pages-socalyrg-com-thankyou-js" */),
  "component---src-pages-socalyrg-com-tracking-js": () => import("./../../../src/pages/socalyrg.com/tracking.js" /* webpackChunkName: "component---src-pages-socalyrg-com-tracking-js" */)
}

